import clsx from "clsx";

import styles from './AsideCard.module.css'

export default function AsideCard({ children, className, style, fixed }) {
    return (
        <div className={clsx(styles.root, className, fixed ? styles.root_fixed : styles.root_sticky)} style={style}>
            {children}
        </div>
    )
}