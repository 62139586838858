import React, {useState, useEffect, useMemo} from 'react';
import {Route, Link, useLocation, useHistory} from "react-router-dom";
import './styles/navbar_profile.css'
import {Navigation} from "../../routers/navigation";
import API from "../../api/API";
import {setCanCreate, setUserCategories, setUserSubscription} from "../../main-store/auth/actions";
import {connect, useSelector} from "react-redux";
import NotVerifiedUser from "../../Components/ProfileComponents/NotVerifiedUser";
import VerificationPopup from "../../Components/ProfileComponents/VerificationPopup";
import NewUserInformationWrapper from "../../Components/NewUserInformationWrapper";
import useResize from "../../Constants/useResize";
import ModalWindow from "../../Components/ModalWindow";
import {bodyOverflow} from "../../Constants/bodyOverflow";
import MainButton from "../../Components/CustomComponents/MainButton";
import {Helmet} from "react-helmet";
import {Divider} from "antd";
import clsx from "clsx";

const MainNavigationProfile = React.memo(({setUserCategories, setUserSubscription, setCanCreate}) => {
        const {innerWidth} = useResize();
        const history = useHistory();

        const [activePage, setActivePage] = useState(null);
        const [profileInfo, setProfileInfo] = useState(null);
        const [loading, setLoading] = useState(null);
        const [activeVerifiedPopup, setActiveVerifiedPopup] = useState(true)
        const [verificationPopup, setVerificationPopup] = useState(false)
        const [userVerified, setUserVerified] = useState(true);
        const [activeStartPopup, setStartActivePopup] = useState(true)
        const [scrollPos, setScrollPos] = useState({
            left: 0,
            width: 51
        })
        let location = useLocation();
        let store = useSelector(store => store.auth.user_data)
        const isOrganization = useMemo(() => store?.role === 'organization', [store?.role])
        const isArtist = useMemo(() => store?.role === 'artist', [store?.role])

        useEffect(() => {
            //todo: delete later
            document.getElementsByTagName('body')[0].classList.add('new_landing_fonts')
            return () => document.getElementsByTagName('body')[0].classList.remove('new_landing_fonts')
        }, [])

        useEffect(() => {
            API().get(`/user/profile/info`)
                .then(res => {
                    if (res.data) {
                        setProfileInfo(res.data)
                        setUserCategories(res.data.categories)
                        setUserSubscription(res.data.subscription)
                        setCanCreate(res.data.can_create)

                        if (res.data.verified) {
                            setActiveVerifiedPopup(false)
                        } else {
                            setActiveVerifiedPopup(true)
                        }
                    }
                    setUserVerified(true)
                })
                .then(() => setLoading(true))
                .catch((err) => {
                    console.log(err)
                    if (err.response) {
                        if (err.response.data.error === 'not verified') {
                            setUserVerified(false)
                        } else {
                            setUserVerified(true)
                        }
                    }
                    setLoading(true)
                })
        }, [setUserCategories, setUserSubscription, setCanCreate])

        function settingsHistoryPush() {
            history.push("/profile/settings#delivery_info");
        }

        const OldSchoolMenuLink = ({label, pathname, path, type, profile}) => {
          if (isArtist && label === 'Profile') {
            return null
          }
          return (isOrganization ? (pathname === 'about' || pathname === 'my_listing' || pathname === 'artist-bnb' || pathname === 'settings' || pathname === 'support' || pathname === 'portfolio') : (!profile || profile === profileInfo?.role) && label)
                ? <Link key={pathname}
                        className={clsx(type === 'withDivider' && 'nav_bar_with_divider', activePage === pathname && 'nav_bar_active_link')}
                        onClick={(e) => {
                            setScrollPos({
                                width: e.target.offsetWidth,
                                left: e.target.offsetLeft
                            })
                            setActivePage(pathname)
                            window.scrollTo(0, 0)
                        }}
                        to={path}>
                    <span>{label}</span>
                </Link>
                : null
        };

        useEffect(() => {
            if (profileInfo) {
                let url = location.pathname.split('/')[2];
                if (url) {
                    let exactLink = Navigation.mainNavigationProfile.filter(el => {
                        if (el.profile) {
                            return el.profile === profileInfo?.role
                        } else return el
                    });
                    if (exactLink.every(e => e.pathname !== url)) window.location.href = '/404-redirect'
                    setActivePage(url)
                } else {
                    window.location.href = '/404-redirect'
                }
            }
        }, [location.pathname, history, profileInfo])

        // useEffect(() => {
        //     let urlNav = location.hash.split('#')[1]
        //     if(urlNav.isArray()) {
        //
        //     }
        //     let sectionHeight = document.getElementById(urlNav).offsetTop;
        //     window.scrollTo({
        //         top: sectionHeight,
        //         behavior: 'smooth'
        //     })
        // },[location])

        const renderSwitchRoute = React.useCallback(() => {
            return (
                <div className={'wrapper_profile__main_content'}>
                    <Helmet>
                        <meta name="robots" content="noindex, follow" />
                    </Helmet>
                    {Navigation.mainNavigationProfile.map((route, index) => (
                        <Route path={route.path}
                               exact={true}
                               component={route.main}
                               key={index}/>
                    ))}
                </div>
            )
        }, [])

        useEffect(() => {
            if (loading) {
                setScrollPos({
                    left: document.querySelector('.nav_bar_active_link')?.offsetLeft,
                    width: document.querySelector('.nav_bar_active_link')?.offsetWidth
                })

                setTimeout(() => {
                    setScrollPos({
                        left: document.querySelector('.nav_bar_active_link')?.offsetLeft,
                        width: document.querySelector('.nav_bar_active_link')?.offsetWidth
                    })
                }, 1)
            }
        }, [loading, innerWidth, location])

        return (
            <Route>
                {loading && !userVerified ?
                    <div className={'wrapper_app wrapper_un_verified_user'}>
                        <h2>Your Artist profile isn't verified</h2>
                        <p>Please wait until the admin allows access. It usually takes several weeks. You'll get an email notification once ready.</p>
                        <p>We can only onboard a limited number of users at this time.</p>
                    </div>
                    : [loading && activeVerifiedPopup && <NotVerifiedUser setVerificationPopup={setVerificationPopup}
                                                                          key={1}
                                                                          setActiveVerifiedPopup={setActiveVerifiedPopup}/>,
                        verificationPopup && <VerificationPopup setVerificationPopup={setVerificationPopup} key={2}/>,

                        loading && <NewUserInformationWrapper {...profileInfo} key={3}
                                                              backgroundArt
                                                              profileInfo={profileInfo}
                                                              setProfileInfo={e => setProfileInfo(e)}/>,
                        loading && <div className={'wrapper_profile'} key={4}>
                            <div className={'wrapper_profile__wrapper_links'}>
                                <div style={{
                                    gridTemplateColumns: `repeat(${Navigation.mainNavigationProfile.filter(i => i.profile === profileInfo?.role || (!i.profile && i.label)).length}, max-content)`
                                }}>
                                    {Navigation.mainNavigationProfile.map((item, index) => {
                                        console.log('item: ', item)
                                      return <OldSchoolMenuLink {...item} key={index}/>
                                    }
                                    )}
                                </div>
                            </div>
                            {innerWidth > 700 ?
                                <div className={'links_scroll_line_wrap'} >
                                    <div style={{width: scrollPos.width + 'px', left: scrollPos.left + 'px'}}
                                         className={'links_scroll_line'}/>
                                </div> : null}

                            {loading ?
                                renderSwitchRoute()
                                : null}
                        </div>,
                        loading ?
                            activeStartPopup && !profileInfo.can_create && profileInfo.role === 'artist' ?
                                <div className={'popup_window_upload_work'} key={42}>
                                    <ModalWindow activeModal={activeStartPopup}
                                                 key={2}
                                                 setActiveModal={val => setStartActivePopup(val)}>
                                        <div className={'portfolio_popup_header_container'}>
                                            <h4>You can’t sell artworks</h4>
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg" onClick={() => {
                                                setStartActivePopup(null)
                                                bodyOverflow(false)
                                            }}>
                                                <path d="M1 1L17 17M1 17L17 1" stroke="black" strokeWidth="2"/>
                                            </svg>
                                        </div>
                                        <p>You have to fill in shipping address.</p>
                                        <div className={'modal-window_buttons'}>
                                            <MainButton type={'button'}
                                                        className={'button_bordered_close_popup'}
                                                        style={{padding: '8px 0', margin: '0', minWidth: '100px'}}
                                                        text={'Cancel'}
                                                        onClick={() => {
                                                            setStartActivePopup(null)
                                                            bodyOverflow(false)
                                                        }}
                                            />
                                            <MainButton type={'button'}
                                                        style={{padding: '8px 0', margin: '0', minWidth: '100px'}}
                                                        className={'button_bordered_fill_in'}
                                                        text={'Fill in'}
                                                        onClick={() => {
                                                            setStartActivePopup(null)
                                                            bodyOverflow(false)
                                                            settingsHistoryPush()
                                                        }}
                                            />
                                        </div>
                                    </ModalWindow>
                                </div> : null : null,
                    ]}
            </Route>
        );
    }
);

const mapStateToProps = state => {
    return {
        user_categories: state.auth.user_categories,
        user_subscription: state.auth.user_subscription,
        can_create: state.auth.can_create,
    }
};

const putDispatchToProps = {
    setUserCategories,
    setUserSubscription,
    setCanCreate,
}

export default connect(mapStateToProps, putDispatchToProps)(MainNavigationProfile);
